import React, { useEffect, useState } from "react";
import { getRequest } from '../../utils/axios';
import { Link } from "react-router-dom";

import Main from "../../layout/Main";
import Loader from "../../components/atoms/Loader";

import './styles.scss';

const Videos = () => {
    const [videos, setVideos] = useState([]);
    const [loading, setLoading] = useState(true);
    const [visibleCount, setVisibleCount] = useState(6);

    useEffect(() => {
        fetchVideos();
    }, []);

    const fetchVideos = async () => {
        setLoading(true);
        try {
            const response = await getRequest('Video/GetVideosWithPageDetail');
            const youtubeVideos = response?.data?.filter(video =>
                video.videoFilePath && video.videoFilePath.includes("youtube.com")
            );
            setVideos(youtubeVideos || []);
        } catch (error) {
            console.error("Error fetching videos:", error);
        }
        setLoading(false);
    };

    const convertToEmbedURL = (url) => {
        return url.replace("watch?v=", "embed/");
    };

    const loadMoreVideos = () => {
        setVisibleCount(prevCount => prevCount + 6);
    };

    return (
        <Main className="b-videos-wrapper">
            <div className="b-width-wrapper">
                <div className="head">
                    <h3>
                        Videos
                    </h3>
                    <p>
                        {videos.length} {videos.length === 1 ? 'Video' : 'Videos'}
                    </p>
                </div>
                {loading ? (
                    <Loader loading={loading} backdrop={true} />
                ) : (
                    <>
                        <div className="video-list">
                            {videos.slice(0, visibleCount).map((video, index) => (
                                <div key={index} className="video-item">
                                    <iframe
                                        src={convertToEmbedURL(video.videoFilePath)}
                                        title={video.videoFileTitle || `Video ${index}`}
                                        frameBorder="0"
                                        allowFullScreen
                                    ></iframe>
                                    <Link
                                        to={`/events/${video.pageRedirectUrl}?tab=video`}
                                        target="_blank"
                                    >
                                        {video.videoFileName}
                                    </Link>
                                </div>
                            ))}
                        </div>
                        {visibleCount < videos.length && (
                            <button className="load-more-btn" onClick={loadMoreVideos}>
                                Load More
                            </button>
                        )}
                    </>
                )}
            </div>
        </Main>
    );
};

export default Videos;
