// BirthdayFireworks.jsx
import React, { useRef, useEffect } from "react";

//////////////////////////////////////////
// 1) HELPER FUNCTIONS
//////////////////////////////////////////
const PI2 = Math.PI * 2;
function random(min, max) {
  return (Math.random() * (max - min + 1) + min) | 0;
}
function timestamp() {
  return new Date().getTime();
}

//////////////////////////////////////////
// 2) FIREWORK / BIRTHDAY CLASSES
//////////////////////////////////////////
class Firework {
  constructor(x, y, targetX, targetY, shade, offsprings) {
    this.dead = false;
    this.offsprings = offsprings;
    this.x = x;
    this.y = y;
    this.targetX = targetX;
    this.targetY = targetY;
    this.shade = shade;
    this.history = [];
  }

  update(delta, ctx, birthday) {
    if (this.dead) return;

    const xDiff = this.targetX - this.x;
    const yDiff = this.targetY - this.y;

    // move towards target
    if (Math.abs(xDiff) > 3 || Math.abs(yDiff) > 3) {
      this.x += xDiff * 2 * delta;
      this.y += yDiff * 2 * delta;
      this.history.push({ x: this.x, y: this.y });
      if (this.history.length > 20) this.history.shift();
    } else {
      // explode into child fireworks
      if (this.offsprings && !this.madeChilds) {
        const babies = this.offsprings / 2;
        for (let i = 0; i < babies; i++) {
          const angle = (PI2 * i) / babies;
          const targetX = this.x + this.offsprings * Math.cos(angle) | 0;
          const targetY = this.y + this.offsprings * Math.sin(angle) | 0;
          birthday.fireworks.push(
            new Firework(this.x, this.y, targetX, targetY, this.shade, 0)
          );
        }
        this.madeChilds = true;
        this.history.shift();
      } else {
        // fade out the tail
        this.history.shift();
      }
    }

    if (this.history.length === 0) {
      this.dead = true;
    } else if (this.offsprings) {
      // draw the trailing effect
      for (let i = 0; i < this.history.length; i++) {
        const point = this.history[i];
        ctx.beginPath();
        ctx.fillStyle = `hsl(${this.shade},100%,${i}%)`;
        ctx.arc(point.x, point.y, 1, 0, PI2, false);
        ctx.fill();
      }
    } else {
      // draw single point
      ctx.beginPath();
      ctx.fillStyle = `hsl(${this.shade},100%,50%)`;
      ctx.arc(this.x, this.y, 1, 0, PI2, false);
      ctx.fill();
    }
  }
}

class Birthday {
  constructor(canvas, ctx) {
    this.canvas = canvas;
    this.ctx = ctx;
    this.width = 0;
    this.height = 0;
    this.fireworks = [];
    this.counter = 0;
    this.resize();
  }

  resize() {
    this.width = this.canvas.width = window.innerWidth;
    this.height = this.canvas.height = window.innerHeight;
    this.spawnA = this.width / 4;
    this.spawnB = (this.width * 3) / 4;
    this.spawnC = this.height * 0.1;
    this.spawnD = this.height * 0.5;
  }

  onClick(x, y) {
    const count = random(3, 5);
    for (let i = 0; i < count; i++) {
      this.fireworks.push(
        new Firework(
          random(this.spawnA, this.spawnB),
          this.height,
          x,
          y,
          random(0, 260),
          random(30, 110)
        )
      );
    }
    this.counter = -1;
  }

  update(delta) {
    // 1) Clear the canvas each frame (NO solid background fill):
    this.ctx.globalCompositeOperation = "source-over";
    this.ctx.clearRect(0, 0, this.width, this.height);

    // 2) Draw fireworks with additive blending
    this.ctx.globalCompositeOperation = "lighter";
    for (let firework of this.fireworks) {
      firework.update(delta, this.ctx, this);
    }

    // auto-spawn fireworks
    this.counter += delta * 3;
    if (this.counter >= 1) {
      this.fireworks.push(
        new Firework(
          random(this.spawnA, this.spawnB),
          this.height,
          random(0, this.width),
          random(this.spawnC, this.spawnD),
          random(0, 360),
          random(30, 110)
        )
      );
      this.counter = 0;
    }

    // remove dead ones
    if (this.fireworks.length > 1000) {
      this.fireworks = this.fireworks.filter((f) => !f.dead);
    }
  }
}

//////////////////////////////////////////
// 3) REACT COMPONENT
//////////////////////////////////////////
const BirthdayFireworks = () => {
  const canvasRef = useRef(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");

    const birthday = new Birthday(canvas, ctx);

    const handleResize = () => birthday.resize();
    window.addEventListener("resize", handleResize);

    const handleClick = (e) => birthday.onClick(e.clientX, e.clientY);
    const handleTouch = (e) => {
      const t = e.touches[0];
      birthday.onClick(t.pageX, t.pageY);
    };
    canvas.addEventListener("click", handleClick);
    canvas.addEventListener("touchstart", handleTouch);

    let then = timestamp();
    function loop() {
      requestAnimationFrame(loop);
      const now = timestamp();
      const delta = (now - then) / 1000;
      then = now;
      birthday.update(delta);
    }
    loop();

    return () => {
      window.removeEventListener("resize", handleResize);
      canvas.removeEventListener("click", handleClick);
      canvas.removeEventListener("touchstart", handleTouch);
    };
  }, []);

  return (
    <canvas
      ref={canvasRef}
      style={{
        // 3) Make canvas transparent with no background
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        display: "block",
        cursor: "crosshair",
        zIndex: 1,
        backgroundColor: "transparent",
      }}
    />
  );
};

export default BirthdayFireworks;
