import React from "react";

import Main from '../../layout/Main';

import './styles.scss'

const Contact = () => {
    return (
        <Main className="b-contact-wrapper">
            <div className="content b-width-wrapper ">

                <h2> Contact Us </h2>

                <div className="boxes">

                    <div className="box location">
                        <h3>
                            Location :
                        </h3>

                        <p>
                            Balbehra Road, Cheeka
                            Kaithal, India 136034
                        </p>
                    </div>

                    <div className="box mail">

                        <h3>
                            Email :
                        </h3>


                        <a href="mailto:info@theindianheights.com">
                            info@theindianheights.com
                        </a>

                    </div>

                    <div className="box phone">

                        <h3>
                            Number :
                        </h3>

                        <p>
                            (+91) 94991-32591 - Front Desk
                            (+91) 74960-25620 - Reception
                            (+91) 74960-25623 - Accounts
                        </p>

                    </div>

                </div>

                <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d13815.171910215777!2d76.3542864!3d30.0427966!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3911d7d0c976e4e3%3A0xef07beebb22899bc!2sThe%20Indian%20Heights%20International%20School!5e0!3m2!1sen!2sin!4v1717179679913!5m2!1sen!2sin"  ></iframe>



            </div>
        </Main>
    );
}

export default Contact;