import React from "react";

import Main from '../../layout/Main';

import logo from "../../assets/icons/logo.jpg"
import sun from "../../assets/images/Sun.png";
import book from "../../assets/images/book.png";
import pen from "../../assets/images/pen.png";
import fire from "../../assets/images/fire.png";
import globe from "../../assets/images/Globe.png";

import './styles.scss'

const Badge = () => {
    return (
        <Main className="b-badge-wrapper">
            <div className="content b-width-wrapper ">

                <h2>
                    Each Symbol in our badge is a Commitment towards students to Develop.
                </h2>
                <img src={logo} alt="logo" />

                <div className="data">

                    <div className="col-1">

                        <div className="box sun">
                            <div className="circle">
                                <img src={sun} />
                            </div>

                            <p>
                                <strong> Sun </strong> signifies life ,energy, being determined, focused and conscious mind in students.
                            </p>
                        </div>

                        <div className="box book">
                            <div className="circle">
                                <img src={book} />
                            </div>

                            <p>
                                <strong> Book </strong> signifies the learning so that they grow and develop maturity and character through education.
                            </p>
                        </div>

                    </div>
                    <div className="col-2">

                        <div className="box pen">
                            <div className="circle">
                                <img src={pen} />
                            </div>

                            <p>
                                <strong> Pen </strong> signifies Intellectual skills and recollection of memories in their mind.
                            </p>
                        </div>

                    </div>
                    <div className="col-3">

                        <div className="box fire">
                            <div className="circle">
                                <img src={fire} />
                            </div>

                            <p>
                                <strong> Fire </strong> signifies passion, creativity and powerful energy in pupils lives, so that they may continue to learn and develop throughout their lives.
                            </p>
                        </div>

                        <div className="box globe">
                            <div className="circle">
                                <img src={globe} />
                            </div>

                            <p>
                                <strong> Globe </strong> signifies aspiration that the student has, when building a career.
                            </p>

                        </div>

                    </div>

                </div>

            </div>
        </Main>
    );
}

export default Badge;