import React, { useState, useEffect } from 'react';
import { getRequest } from "../../utils/axios";
import { Link } from 'react-router-dom';

import Main from '../../layout/Main';
import Loader from "../../components/atoms/Loader";

import jobImage1 from '../../assets/images/job-image-1.jpg';
import jobImage2 from '../../assets/images/job-image-2.jpg';
import jobImage3 from '../../assets/images/job-image-3.jpg';

import './styles.scss';

const JOBS_PER_PAGE = 6;

const Jobs = () => {

    const [allJobs, setAllJobs] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [displayedJobs, setDisplayedJobs] = useState([]);

    useEffect(() => {
        getJobs();
    }, []);

    const getJobs = async () => {
        try {
            const response = await getRequest(`Page/GetActivePagesByCategoryTypeName/jobs`);
            const jobsData = response.data || [];
            setAllJobs(jobsData);
            setDisplayedJobs(jobsData.slice(0, JOBS_PER_PAGE));
            localStorage.setItem('docData', JSON.stringify(jobsData));
        } catch (error) {
            console.error("Error fetching jobs data:", error);
            setError("Failed to load jobs");
        } finally {
            setLoading(false);
        }
    }

    const loadMoreJobs = async (event) => {
        event.preventDefault();
        const currentLength = displayedJobs.length;
        const nextJobs = allJobs.slice(currentLength, currentLength + JOBS_PER_PAGE);
        setDisplayedJobs([...displayedJobs, ...nextJobs
        ]);
    }

    return (
        <Main className="b-jobs-wrapper">
            <Loader loading={loading} backdrop={true} />
            <div className='b-width-wrapper wrapper'>
                <h1>
                    Jobs
                </h1>
                {loading ? (
                    <p> Loading Jobs ... </p>
                ) : error ? (
                    <p> {error} </p>
                ) : (
                    <>
                        <div className='b-jobs-containers'>
                            <div className='images'>
                                <div className='upper-image'>
                                    <img src={jobImage1} alt='jobImage1' />
                                </div>
                                <div className='lower-image'>
                                    <img src={jobImage2} alt='jobImage2' />
                                    <img src={jobImage3} alt='jobImage3' />
                                </div>
                            </div>
                            <div className='content'>
                                {displayedJobs.map((job, index) => (
                                    <div key={index} className='b-job'>
                                        <Link to={`/jobs/${job.pageUrl}`}>
                                            <h2>{job.pageTitle}</h2>
                                        </Link>
                                        <p>{job.shortDescription}</p>
                                        <Link to={`/jobs/${job.pageUrl}`} className='b-read-more'>Read More</Link>
                                    </div>
                                ))}
                            </div>
                        </div>
                        {displayedJobs.length < allJobs.length && (
                            <button onClick={loadMoreJobs} className='b-load-more-btn'>Load More</button>
                        )}
                    </>
                )}
            </div>
        </Main>
    );
}

export default Jobs;