import React, { useState, useEffect, useRef } from 'react';
import Slider from "react-slick";
import { Link, useNavigate } from 'react-router-dom';
import { Card, CardActionArea, CardContent, CardMedia, Typography } from '@mui/material';
import { getRequest } from "../../utils/axios";

import Main from '../../layout/Main';
import Firework from "../../components/organs/Firework";

import section1Image1 from "../../assets/images/home-section-1-image-1.jpeg";
import section1Image2 from "../../assets/images/home-section-1-image-2.jpeg";
import section1Image3 from "../../assets/images/home-section-1-image-3.jpeg";
import section1Image4 from "../../assets/images/home-section-1-image-4.jpeg";
import section2Image from "../../assets/images/home-section-2-image.jpeg";
import eventsImage1 from "../../assets/images/home-section-events-image-1.jpeg";
import eventsImage2 from "../../assets/images/home-section-events-image-2.jpeg";
import eventsImage3 from "../../assets/images/home-section-events-image-3.jpeg";

import galleryImage1 from "../../assets/images/gallery-image-1.jpeg";
import galleryImage2 from "../../assets/images/gallery-image-2.jpeg";
import galleryImage3 from "../../assets/images/gallery-image-3.jpeg";
import galleryImage4 from "../../assets/images/gallery-image-4.jpeg";
import galleryImage5 from "../../assets/images/gallery-image-5.jpeg";
import galleryImage6 from "../../assets/images/gallery-image-6.jpeg";
import galleryImage7 from "../../assets/images/gallery-image-7.jpeg";
import galleryImage8 from "../../assets/images/gallery-image-8.jpeg";
import galleryImage9 from "../../assets/images/gallery-image-9.jpeg";
import galleryImage10 from "../../assets/images/gallery-image-10.jpeg";

import chess from "../../assets/images/chess.jpeg";
import carromBoard from "../../assets/images/carrom-board.jpeg";
import cricket from "../../assets/images/cricket.jpeg";
import running from "../../assets/images/running.jpeg";
import skating from "../../assets/images/skating.jpg";
import karate from "../../assets/images/karate.jpg";
import french from "../../assets/images/french-class.jpg";

import calendar from "../../assets/icons/calendar.svg";
import teacher from "../../assets/icons/teacher.svg";
import certificate from "../../assets/icons/certificate.svg";

import birthdayVideo from "../../assets/video/birthday.webm"
import birthdayVideo1 from "../../assets/video/birthday-2.webm"
import birthdayVideo2 from "../../assets/video/birthday-3.webm"

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./styles.scss";


const Home = () => {
    const [events, setEvents] = useState([]);
    const navigate = useNavigate();
    const [birthdays, setBirthdays] = useState([]);
    const sliderRef = useRef(null);

    const videos = [birthdayVideo, birthdayVideo1, birthdayVideo2];

    useEffect(() => {
        const fetchEvents = async () => {
            try {
                const response = await getRequest(`Page/GetTopActivePagesByCategoryTypeName/events/3`);
                setEvents(response.data);
            } catch (error) {
                console.error("Error fetching events data:", error);
            }
        };

        fetchEvents();
    }, []);

    useEffect(() => {
        const fetchBirthdays = async () => {
            try {
                const response = await fetch("https://apiqa.theindianheights.com/api/Public/GetInstituteStudentsBirthday/-fxtRbEY-ie5dGDUEdMOPA/0");
                const data = await response.json();
                setBirthdays(data);
            } catch (error) {
                console.error("Error fetching birthdays:", error);
            }
        };
        fetchBirthdays();
    }, []);


    const handleEventClick = async (categoryTypeName, pageUrl) => {
        try {
            const pageResponse = await getRequest(`Page/GetPageByUrl/${categoryTypeName}/${pageUrl}`);
            const { url } = pageResponse.data;
            navigate(url);
        } catch (error) {
            console.error("Error fetching event page URL:", error);
        }
    };

    useEffect(() => {
        const handleMouseMove = (event) => {
            const circles = document.querySelectorAll('.circle-1, .circle-2, .circle-3, .circle-4, .event-circle-3, .event-circle-2');
            circles.forEach(circle => {
                if (!circle.closest('.overlay')) {
                    const speed = circle.getAttribute('data-speed');
                    const x = (window.innerWidth + event.pageX * speed) / 200;
                    const y = (window.innerHeight + event.pageY * speed) / 200;
                    circle.style.transform = `translateX(${x}px) translateY(${y}px)`;
                }
            });
        };

        document.addEventListener('mousemove', handleMouseMove);

        const observer = new IntersectionObserver((entries) => {
            entries.forEach(entry => {
                const circle = entry.target;
                if (entry.isIntersecting) {
                    circle.style.opacity = 0.7;
                } else {
                    circle.style.opacity = 1;
                }
            });
        }, {
            threshold: 0.1
        });

        const eventCircle3 = document.querySelector('.event-circle-3, .event-circle-2');
        const images = document.querySelectorAll('.overlay img');

        images.forEach(image => {
            observer.observe(eventCircle3);
        });

        const rightSideImages = document.querySelectorAll('.right-side .image');
        rightSideImages.forEach((image, index) => {
            setTimeout(() => {
                image.classList.add('visible');
            }, index * 200);
        });

        const aboutBoxesObserver = new IntersectionObserver((entries) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('visible');
                }
            });
        }, {
            threshold: 0.1
        });

        const aboutBoxes = document.querySelectorAll('.about-boxes .box, .about-boxes .big-box');
        aboutBoxes.forEach((box, index) => {
            setTimeout(() => {
                aboutBoxesObserver.observe(box);
            }, index * 300);
        });

        return () => {
            document.removeEventListener('mousemove', handleMouseMove);
        };
    }, []);

    const cards = [
        { image: chess, title: "Chess" },
        { image: carromBoard, title: "Carrom Board" },
        { image: cricket, title: "Cricket" },
        { image: running, title: "Athletics" },
        { image: skating, title: "Skating" },
        { image: karate, title: "Karate" },
        { image: french, title: "French" }
    ];

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    const prevBtn = () => {
        if (sliderRef.current) sliderRef.current.slickPrev();
    };

    const nextBtn = () => {
        if (sliderRef.current) sliderRef.current.slickNext();
    };


    const birthdaySliderSettings = {
        dots: false,
        infinite: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        speed: 500,
        responsive: [
            {
                breakpoint: 1024,
                settings: { slidesToShow: 3, slidesToScroll: 1 },
            },
            {
                breakpoint: 600,
                settings: { slidesToShow: 2, slidesToScroll: 1 },
            },
            {
                breakpoint: 480,
                settings: { slidesToShow: 1, slidesToScroll: 1 },
            },
        ],
    };

    return (
        <Main className="b-home-wrapper">
            <div className="b-wrapper">
                <div className="content b-width-wrapper">
                    <div className="left-side">
                        <h1>The Smarter Way to learn<span> Anything </span> </h1>
                        <p>
                            Our school is a place where students can learn and grow.
                            We offer a wide range of programs to help students reach their full potential.
                            Our dedicated teachers and staff are here to support students every step of the way.
                            We believe that every student has the ability to succeed, and we are committed to helping them achieve their goals.
                            We invite you to explore our website to learn more about our school and the programs we offer.
                            If you have any questions, please don't hesitate to contact us. We look forward to welcoming you to our school community!
                        </p>
                    </div>

                    <div className="right-side">
                        <img src={section1Image1} alt="section1Image1" className="image image-1" />
                        <div className="circle-1" data-speed="5"></div>
                        <img src={section1Image2} alt="section1Image2" className="image image-2" />
                        <div className="circle-2" data-speed="3"></div>
                        <img src={section1Image3} alt="section1Image3" className="image image-3" />
                        <div className="circle-3" data-speed="4"></div>
                        <img src={section1Image4} alt="section1Image4" className="image image-4" />
                        <div className="circle-4" data-speed="2"></div>
                    </div>
                </div>

                <div className='about'>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="#f3fcff" fill-opacity="1" d="M0,288L48,272C96,256,192,224,288,192C384,160,480,128,576,144C672,160,768,224,864,245.3C960,267,1056,245,1152,224C1248,203,1344,181,1392,170.7L1440,160L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"></path></svg>
                    <div className='background'>
                        <div className='content b-width-wrapper'>
                            <div className='heading'>
                                <h1>
                                    Welcome to <span> TIHIS </span>
                                </h1>
                                <p>
                                    Our Mission your Door to future
                                </p>
                            </div>

                            <div className='about-boxes'>

                                <div className='box'>
                                    <img src={teacher} />
                                    <h4>
                                        Experienced Teachers
                                    </h4>
                                    <p>
                                        Our team of educators is comprised of highly qualified professionals dedicated to fostering a positive and enriching learning environment.
                                    </p>
                                </div>

                                <div className='big-box'>
                                    <img src={section2Image} />
                                    <h4>Top Learning Program</h4>
                                    <p>
                                        Our program provides exceptional learning experiences, combining expert instruction with a supportive environment to ensure student success.
                                    </p>
                                </div>

                                <div className='box'>
                                    <img src={certificate} />
                                    <h4>Global Certificate</h4>
                                    <p>
                                        Olympiads let students compete globally, showcasing their skills and boosting academic performance, confidence, and critical thinking.
                                    </p>
                                </div>

                            </div>

                        </div>

                    </div>
                </div>

                <div className='events'>
                    <div className='b-width-wrapper'>
                        <div className='left-images'>
                            <div className='upper-side'>
                                <h1>
                                    <span> Events </span>
                                </h1>
                                <p>
                                    Learning record store (lrs) bite-sized learning avatar elearning, multimedia scenario serious games
                                </p>
                            </div>
                            <div className='lower-side'>
                                <div className='circles'>
                                    <div className='circle-1' data-speed='5'></div>
                                    <div className='event-circle-2' data-speed='3'></div>
                                    <div className='event-circle-3' data-speed='10'></div>
                                </div>

                                <div className='overlay'>
                                    <div className='left'>
                                        <img src={eventsImage1} alt="section1Image1" className="event-image-1" />
                                    </div>
                                    <div className='right'>
                                        <img src={eventsImage2} alt="section1Image2" className="image image-2" />
                                        <img src={eventsImage3} alt="section1Image3" className="image image-3" />
                                    </div>
                                </div>

                            </div>

                        </div>
                        <div className='right-content'>
                            <div className='boxes'>
                                {events.map((event, index) => {
                                    const isMiddleBox = index === Math.floor(events.length / 2);
                                    return (
                                        <div
                                            className={`box ${isMiddleBox ? "center" : ""}`}
                                            key={index}
                                        >
                                            <div className='d'>
                                                <img src={calendar} alt="calendar" />
                                                <h3>{new Date(event.publishDate).toLocaleDateString()}</h3>
                                            </div>
                                            <Link
                                                to={{
                                                    pathname: `events/${event.pageUrl}`,
                                                    state: { categoryTypeName: event.categoryTypeName, pageUrl: event.pageUrl }
                                                }}
                                                onClick={() => handleEventClick(event.categoryTypeName, event.pageUrl)}
                                            >
                                                <h3>{event.pageName}</h3>
                                            </Link>
                                            <p>{event.shortDescription}</p>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                </div>

                <div className='classes'>
                    <div className='b-width-wrapper'>
                        <div className='content'>
                            <h3> Our <span> classes </span> </h3>
                            <p>
                                Application programming interface voice-over (vo) instructor led training curation, user created content
                            </p>
                        </div>

                        <div className='slider'>
                            <Slider {...settings}>
                                {cards.map((card, index) => (
                                    <Card key={index} className="custom-card">
                                        <CardActionArea>
                                            <CardMedia className='images'
                                                component="img"
                                                height="140"
                                                image={card.image}
                                                alt={card.title}
                                            />
                                            <CardContent className='media'>
                                                <Typography component="div" className="custom-card-title">
                                                    {card.title}
                                                </Typography>
                                            </CardContent>
                                        </CardActionArea>
                                    </Card>
                                ))}
                            </Slider>
                        </div>

                    </div>
                </div>


                <div className='gallery'>
                    <div className='b-width-wrapper'>
                        <div className='text'>
                            <h3>
                                Study Process <span> Gallery </span>
                            </h3>
                            <p>
                                Instructor led training digital learning cbt completion criteria learning management system cognitive load byod self-directed learning knowledge
                            </p>
                        </div>

                        <div className='gallery-images'>
                            <div className='moment-balls'></div>
                            <div className='pictures'>
                                <div className='col-1 col'>
                                    <img src={galleryImage1} className='school-image-1' alt="school" />
                                    <img src={galleryImage2} className='school-image-2' alt="school" />
                                </div>
                                <div className='col-2 col'>
                                    <img src={galleryImage3} className='school-image-1' alt="school" />
                                    <img src={galleryImage4} className='school-image-2' alt="school" />
                                </div>
                                <div className='col-3 col'>
                                    <img src={galleryImage5} className='school-image-1' alt="school" />
                                    <img src={galleryImage6} className='school-image-2' alt="school" />
                                </div>
                                <div className='col-4 col'>
                                    <img src={galleryImage7} className='school-image-1' alt="school" />
                                    <img src={galleryImage8} className='school-image-2' alt="school" />
                                </div>
                                <div className='col-5 col'>
                                    <img src={galleryImage9} className='school-image-1' alt="school" />
                                    <img src={galleryImage10} className='school-image-2' alt="school" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="birthday-slider">
                    <Firework />
                    <div className="b-width-wrapper">
                        <h3>
                            Happy <span>Birthday</span>
                        </h3>

                        <div className='birthday-wrapper'>
                            <button className="slider-btn prev-btn none" onClick={prevBtn} />

                            <div className='celebrate'>
                                <Slider {...birthdaySliderSettings} ref={sliderRef}>
                                    {birthdays?.map((student, index) => {
                                        const videoToShow = videos[index % videos.length];
                                        return (
                                            <div key={index} className="birthday-card">
                                                <video className="birthday-video" src={videoToShow} autoPlay muted loop />
                                                <p className="name">{student.name}</p>
                                                <p>
                                                    {student.class} - {student.section}
                                                </p>
                                                <p className="date">
                                                    {new Date(student.studentDOB).toLocaleDateString("en-GB", {
                                                        day: "numeric",
                                                        month: "long",
                                                    })}
                                                </p>
                                            </div>
                                        );
                                    })}
                                </Slider>
                            </div>
                            <button className="slider-btn next-btn none" onClick={nextBtn} />
                        </div>
                    </div>
                </div>

                <div className='wave'>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="#f3fcff" fill-opacity="1" d="M0,256L48,250.7C96,245,192,235,288,224C384,213,480,203,576,213.3C672,224,768,256,864,256C960,256,1056,224,1152,218.7C1248,213,1344,235,1392,245.3L1440,256L1440,0L1392,0C1344,0,1248,0,1152,0C1056,0,960,0,864,0C768,0,672,0,576,0C480,0,384,0,288,0C192,0,96,0,48,0L0,0Z"></path></svg>
                </div>

            </div>

        </Main >
    );
}

export default Home;
